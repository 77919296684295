<template>
    <div class="action-schedule">
        <div class="message">
            <div class="message-top">
                <img :src="require('@/assets/images/icons/message.png')" alt="" />
                <span>{{ title }}</span>
            </div>
            <div class="message-content" v-if="message.mtype.includes('change')">
                <div class="desc" v-for="(d, idx) in desc" :key="idx" :class="{ 'li-dot': desc.length > 1 }">
                    {{ d }}
                </div>
            </div>
            <div class="message-content" v-else>
                {{ body }}
            </div>
            <div class="grey-box m-b-8">
                <div class="user-profile">
                    <img :src="photoUrl" alt="" />
                    <div class="profile-info">
                        <div class="age" v-html="age" />
                        <div class="region" v-html="content.region" />
                        <div class="job" v-html="content.job" />
                    </div>
                </div>
            </div>
            <div class="reschedule-message-wrap">
                <div class="reschedule-title">메세지 내용</div>
                <div class="reschedule-body">
                    {{ content.reject_message }}
                </div>
            </div>
            <BottomButton
                v-if="showButton"
                @click="submit"
                :disabled="disabled"
                :non-fixed="true"
                :label="`일정 다시 입력`"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionRescheduleSent',
    props: ['message'],
    computed: {
        showButton() {
            // 남성의 경우에는 보이지 않는다.
            if (this.message.mtype.includes('change')) return false
            else return true
        },
        disabled() {
            return this.content.status === 1
        },
        title() {
            // 전달 했을 때 : (남성)
            if (this.message.mtype.includes('change')) {
                return '메시지를 전달했습니다.'
            } else if (this.message.mtype.includes('reoffer')) {
                return '남성분이 메시지를 보내셨어요!'
            }
            return ''
            // 전달 받았을 때 : (여성)
        },
        body() {
            // 전달 했을 때 : (남성)
            // if (this.message.mtype.includes('change')) {
            //     return "작성해주신 메시지를 전달했습니다. 여성분이 새로운 일정을 입력해 주실 때까지 기다려주세요."
            // }
            if (this.message.mtype.includes('reoffer')) {
                // 전달 받았을 때 : (여성)
                return '남성분께서 가능한 일정이 없습니다😢 메시지를 참고하여 다시 일정을 정해주세요'
            }
            return ''
        },
        desc() {
            if (this.message.mtype.includes('change')) {
                return [
                    '작성해주신 메시지를 전달했습니다. 여성분이 새로운 일정을 입력해 주실 때까지 기다려주세요.',
                    '하루가 지나도 새로운 일정을 받지 못하면 고객센터에 문의해주세요. (알림을 꺼놓은 회원분들이 일정 입력이 늦는 경우가 있습니다. 이 경우 매니저가 개별적으로 연락드려서 도와드릴 수 있습니다.)',
                    // "변경/취소는 ‘약속 관리' 탭에서 할 수 있습니다.",
                ]
            } else {
                return null
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        isAtype() {
            return this.message.mtype === 'schedule-reoffer'
        },
    },
    methods: {
        submit() {
            this.$stackRouter.push({
                name: 'PromisePage',
                props: {
                    message: this.message,
                    isAtype: this.isAtype,
                    userInfo: {
                        photoUrl: this.photoUrl,
                        age: this.age,
                        region: this.content.region,
                        job: this.content.job,
                    },
                    reschedule: true,
                },
            })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
.reschedule-message-wrap {
    background-color: #f7f8f9;
    margin: 8px 0px;
    border-radius: 12px;
    padding: 8px 16px;
    .reschedule-title {
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        margin: 4px 0px;
        color: #94999e;
    }
    .reschedule-body {
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        margin: 4px 0px;
    }
}
.grey-box {
    background: $grey-01;
    padding: 8px;
    font-size: 14px;
    border-radius: 12pxcc;

    .user-profile {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #111111;

        img {
            width: 64px;
            height: 64px;
            border: 1px solid white;
            border-radius: 8px;
            margin-right: 14px;
            object-fit: contain;
        }
    }
    .title-grey {
        @include spoqa-f-medium;
        font-size: 12px;
        color: #94999e;
        margin-bottom: 4px;
    }
    .content-grey {
        line-height: 1.57;
        @include spoqa-f-regular;
    }
}
</style>
